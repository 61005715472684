<template>
	<div>
		<base-table :headers="headers" :items="employees" table-top paginate>
			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="showReports(data.item)">
						<span class="align-middle">View</span>
					</b-button>
				</div>
			</template>
		</base-table>
		<b-modal v-model="reportsModal" :title="modalTitle" size="lg" hide-footer centered>
			<Details :employee="employee" />
		</b-modal>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, BModal } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import Details from './Details.vue';

export default {
	name: 'Employee',

	components: { BaseTable, BButton, BModal, Details },

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID' },
			{ key: 'name', label: 'Employee name' },
			{ key: 'phone', label: 'Phone number' },
			{ key: 'position', label: 'Position' },
			{ key: 'actions' },
		],

		employees: [],
		reportsModal: false,
		employee: undefined,
	}),

	computed: {
		modalTitle() {
			return this.employee?.name;
		},
	},

	created() {
		this.fetchEmployees();
	},

	methods: {
		showReports(employee) {
			this.employee = employee;
			this.reportsModal = true;
		},

		async fetchEmployees() {
			try {
				this.employees = await store.dispatch('employees/getEmployees', this.$route.params.id);
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
